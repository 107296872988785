import React from "react";
import ListAllCarousel from "./ListAllCarousel";
import { HiArrowLongLeft } from "react-icons/hi2";
import { NavLink } from "react-router-dom";
import ServiceBuyerNear from "./ServiceBuyerNear";
import ListCommunity from "./ListCommunity";
export default function ListallMain() {
  return (
    <>
      <div class="flex items-center justify-center h-48 mb-8 ">
        <ListAllCarousel />
      </div>
      <div className="flex flex-row items-center">
        <NavLink to="/">
          <div
            className=" lg:relative  lg:w-[100%]  lg:left-[1rem]    flex flex-row item-center justify-start gap-3 z-10 top-[11rem]"
            style={{ alignItems: "center" }}
          >
            <span className="text-[32px] text-[#4b5563]">
              <HiArrowLongLeft />
            </span>
            <h1 className="font-semibold  text-[#4b5563] text-[20px]">
              Go Back to home page
            </h1>
          </div>
        </NavLink>
      </div>
      <div className="mt-[13rem]">
        <ServiceBuyerNear />
      </div>

      <div className="mb-8">
        <ListCommunity />
      </div>
    </>
  );
}
