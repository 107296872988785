import React from "react";

export default function Promoted_Product_Card() {
  return (
    <>
      <>
        <div className="flex flex-row bg-white  shadow-md rounded-md drop-shadow-md">
          <div className="flex-none w-[7rem] h-[8rem] mr-4 overflow-hidden rounded-md">
            {/* Your image goes here */}
            <img
              src="https://images.unsplash.com/photo-1613040809024-b4ef7ba99bc3?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              alt="Card Image"
              className="w-full h-full object-cover"
            />
          </div>
          <div className="flex-grow p-4">
            {/* Your text content goes here */}
            {/* <h2 className="text-lg font-bold mb-2">Card Title</h2> */}
            <p className="text-gray-600">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do
              eiusmod tempor incididunt
            </p>
          </div>
        </div>
        {/* stylesheet */}

      </>
    </>
  );
}
