import React from 'react'
import service from "../../staticimages/statsic images.png"
import { Link } from 'react-router-dom'


function Markettrend() {
  return (
    <>
     <div className="shadow-l h-[280px] bg-[#ffff] rounded-md">
        <h3 className='font-all text-black font-bold relative lg:top-[11px] lg:left-[22px]'>Find Latest Market trends <br /> and resources</h3>

        <div className="flex flex-row justify-center items-center mt-3">
            <div className="text lg:w-[160px]" style={{lineHeight:'1px'}}> 
            <h3 className='leading-tight inline  font-all' style={{lineHeight:'18px'}}>Find out the nearset  market trends and get  the best price for   your money</h3>

            </div>
            <div className="image">
                <img src={service} className='lg:h-[136px]' alt="" />
            </div>
        </div>

        <div className="card-action flex justify-center mt-[1rem]">
         <Link to="/marketTrend" ><button className='bg-[#F8F8F8] shadow-md lg:mx-auto p-[6px] w-[13rem] rounded-full transform hover:scale-105 duration-500 ease-in-out hover:bg-[#CB2229]  hover:text-white'>View Market Trends</button>
         </Link>   
        </div>
        </div> 
    </>
  )
}

export default Markettrend
