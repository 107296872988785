import React from 'react'

export default function MostPopular() {
  return (
 <>
   <div className="border border-gray-400 rounded-md w-[99%] mx-auto mt-9 ">

<div className="flex flex-row justify-start  items-center ml-8 gap-[50rem] p-[11px]">
<h1 className="font-all font-semibold text-[22px]">
  Most Popular
</h1>
{/*  */}
{/*  */}



  </div>
  {/*  */}

<div className="flex flex-wrap  gap-4 mt-4 justify-center mb-6">

<img src="https://img.freepik.com/free-photo/man-woman-with-medical-masks-out-grocery-shopping-with-shopping-cart_23-2149483155.jpg?size=626&ext=jpg" className='h-[224px] rounded-md' alt="" />
<img src="https://img.freepik.com/free-photo/shopping-lifestyle-concept-positive-female-shopper-spends-weekend-fashionable-store-holds-bouquet-stands-near-clothing-rack-big-sale_273609-32928.jpg?w=1060&t=st=1688849873~exp=1688850473~hmac=2c6d400f6f778d00afca4342288709cd4e162b8f4b0f65b8f09f77c5f8c0e6eb" className='h-[224px] rounded-md' alt="" />
<img src='https://img.freepik.com/premium-photo/interior-shot-racks-with-shirts-undershirts-jeans_88135-5868.jpg?w=1060' className='h-[224px] rounded-md' alt="" />

</div>

    </div>
 
 </>
  )
}
