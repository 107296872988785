import React from 'react'
import giftbox from "./giftbox.jpeg"
function TotallRewards() {
  return (
    <>
    <div className="bg-[#FAC8CA]  w-[22rem] h-[257px] ">
  <h1 className='font-extrabold relative lg:top-[46px] left-[24px] inline text-[19px] font-all'>My Rewards</h1>

<hr className='border-[1px] border-dashed relative top-[-17px]  border-[#738482]'  />
<hr className='border-[1px] border-dashed relative top-[207px]  border-[#738482]'  />

  <h1 className='font-extrabold font-all text-center relative top-[4rem] text-[25px]'>5015 Points</h1>


     <img src={giftbox} className='rounded-[58px] h-[13rem] mx-auto relative top-[8rem] image-coin ' alt="" /> 
     </div>
    </>
  )
}

export default TotallRewards
