import React, { useEffect, useState } from "react";
import { registerUserdata } from "../../../../api/userRegestration/CollectionForm";
import { useWallet } from "../../../Wallet/Context/WalletContext";
import { MdModeEditOutline } from "react-icons/md";
import { IoMdCreate } from "react-icons/io";
import Spinner from "../../../../component/Spinner";

function EditProfile({ togglemode, profileDetails, view }) {
  const { setProfiledata } = useWallet();

  const { profiledata } = useWallet();
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageBase64, setImageBase64] = useState(null);
  const [isFileInputVisible, setIsFileInputVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedCoverImage, setSelectedCoverImage] = useState(null);
  const [coverImageBase64, setCoverImageBase64] = useState(null);
  
  const [formData, setFormData] = useState({
    fullname: profiledata.name,
    email: "",
    aboutYourself: profiledata.bio,
    location: profiledata.location,
    useCurrentLocation: "",
    interest: "",
    identification: "",
    imageFile: null,
    coverImageFile: null,

  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);

      const userData = {
        name: formData.fullname,
        userName: formData.fullname,
        email: formData.fullname,
        gender: "MAlE",
        bio: formData.aboutYourself,
        dob: "Nothing",
        facebook: "https://www.fac00&creative=bookmark",
        twitter: "https://twitter.com/",
        instagram: "https://instagram.com/",
        linkedIn:
          "https://www.linkedin.com/signup/cold-join?session_redirect=https%3A%2F%2Fwww%2Elinkedin%2Ecom%2Ffeed%2F&trk=login_reg_redirect",
        location:
        formData.location,
        profilePic: imageBase64 || profiledata.profilePic,
        coverPic: coverImageBase64 || profiledata.coverPic,

      };

      const registeredUser = await registerUserdata(userData);
      console.log("Registered User:", registeredUser.result);
      setProfiledata(registeredUser.result);
      console.log("state updated siccsfully ");
      setIsLoading(false);
    } catch (error) {
      console.error("Error:", error);
      setError("An error occurred while updating the profile.");
      setIsLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "text" ? value : prevData[name],
    }));
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl);
      convertImageToBase64(file);
    } else {
      setSelectedImage(null);
      setImageBase64(null);
    }
  };
  const handleCoverImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSelectedCoverImage(imageUrl);
      convertCoverImageToBase64(file);
    } else {
      setSelectedCoverImage(null);
      setCoverImageBase64(null);
    }
  };
  
  const convertCoverImageToBase64 = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const base64Data = e.target.result;
      setCoverImageBase64(base64Data);
    };
    reader.readAsDataURL(file);
  };
  
  const handleClickOnProfileImage = () => {
    document.getElementById("profileImageInput").click();
  };

  const convertImageToBase64 = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const base64Data = e.target.result;
      setImageBase64(base64Data);
    };
    reader.readAsDataURL(file);
  };

  return (
    <>
      <div className="bg-layout shadow-md drop-shadow-md lg:w-[155vh] mb-[7rem] lg:ml-[268px] relative top-[1rem] h-max">
        <h1 className="fade-in-left font-all text-center font-semibold lg:text-[22px] mb-2">
          Edit Update Profile
        </h1>
        <section className="bg-white shark:bg-gray-900">
          <div className="max-w-2xl px-4 py-8 mx-auto lg:py-16">
            <form action="#">
            <div className="--banner-bg mt-8 relative">

    <>
      <img
        className="rounded-md h-[10.1rem] w-[100%]"
        src={coverImageBase64 || profiledata.coverPic}
        alt=""
      />
      <label
        htmlFor="coverImageInput"
        className="cursor-pointer absolute top-0 right-0 p-2"
      >
        <IoMdCreate className="text-white bg-[#cb2229] rounded-full shadow-md" />
      </label>
      <input
        id="coverImageInput"
        type="file"
        accept="image/*"
        onChange={handleCoverImageChange}
        style={{ display: "none" }}
      />
    </>

</div>

              <div className="flex flex-row">
                <div className="--user-img">
                  <label
                    htmlFor="profileImageInput"
                    className="cursor-pointer relative"
                  >
                    <img
                      className="h-[6rem] w-[6rem] rounded-full object-cover hover:opacity-5 cursor-pointer"
                      src={selectedImage || profiledata.profilePic}
                      alt="Avatar"
                      onClick={handleClickOnProfileImage}
                    />
                    <div className="absolute inset-0 rounded-full shadow-inner"></div>
                    <div className="absolute top-[73px] right-0 p-2">
                    <MdModeEditOutline  className="text-white bg-[#cb2229] rounded-full shadow-md" />

                    </div>
                  </label>
                  <input
                    id="profileImageInput"
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                    style={{ display: "none" }}
                  />
                </div>
              </div>
              <div className="grid gap-4 mb-4 sm:grid-cols-2 sm:gap-6 sm:mb-5">
                <div className="sm:col-span-2">
                  <label
                    htmlFor="name"
                    className="block mb-2 text-sm font-semibold font-all text-gray-900 shark:text-white"
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    name="fullname"
                    id="name"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 shark:bg-gray-700 shark:border-gray-600 shark:placeholder-gray-400 shark:text-white shark:focus:ring-primary-500 shark:focus:border-primary-500"
                    value={formData.fullname}
                    onChange={handleInputChange}
                    required=""
                  />
                </div>
                <div className="w-full">
                  <label
                    htmlFor="brand"
                    className="block mb-2 text-sm font-medium text-gray-900 shark:text-white"
                  >
                    Bio
                  </label>
                  <input
                    type="text"
                    id="brand"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 shark:bg-gray-700 shark:border-gray-600 shark:placeholder-gray-400 shark:text-white shark:focus:ring-primary-500 shark:focus:border-primary-500"
                    name="aboutYourself"
                    value={formData.aboutYourself}
                    onChange={handleInputChange}
                    required=""
                  />
                </div>
                <div className="w-full">
                  <label
                    htmlFor="price"
                    className="block mb-2 text-sm font-medium text-gray-900 shark:text-white"
                  >
                    Location
                  </label>
                  <input
                    type="text"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 shark:bg-gray-700 shark:border-gray-600 shark:placeholder-gray-400 shark:text-white shark:focus:ring-primary-500 shark:focus:border-primary-500"
                    name="location"
                    value={formData.location}
                    onChange={handleInputChange}
                    required=""
                  />
                </div>
                <div>
                  <label
                    htmlFor="category"
                    className="block mb-2 text-sm font-medium text-gray-900 shark:text-white"
                  >
                    Identification
                  </label>
                  <select
                    id="category"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 shark:bg-gray-700 shark:border-gray-600 shark:placeholder-gray-400 shark:text-white shark:focus:ring-primary-500 shark:focus:border-primary-500"
                  >
                    <option selected="">Electronics</option>
                    <option value="TV">TV/Monitors</option>
                    <option value="PC">PC</option>
                    <option value="GA">Gaming/Console</option>
                    <option value="PH">Phones</option>
                  </select>
                </div>
                <div>
                  <label
                    htmlFor="category"
                    className="block mb-2 text-sm font-medium text-gray-900 shark:text-white"
                  >
                    Interest
                  </label>
                  <select
                    id="category"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 shark:bg-gray-700 shark:border-gray-600 shark:placeholder-gray-400 shark:text-white shark:focus:ring-primary-500 shark:focus:border-primary-500"
                  >
                    <option selected="">Electronics</option>
                    <option value="TV">TV/Monitors</option>
                    <option value="PC">PC</option>
                    <option value="GA">Gaming/Console</option>
                    <option value="PH">Phones</option>
                  </select>
                </div>
              </div>
              {error && <p className="text-red-500">{error}</p>}
              {isLoading ? (
                <button
                onClick={handleSubmit}
                class=" bg-[#CB2229] text-white rounded-full font-all p-[6px] lg:mt-[0.5rem] w-[18rem]   transform hover:scale-105 duration-500 ease-in-out relative mx-auto flex justify-center items-center"
              >
                Updating profile <Spinner/>
              </button>
              ) : (
                <button
                onClick={handleSubmit}
                class=" bg-[#CB2229] text-white rounded-full font-all p-[6px] lg:mt-[0.5rem] w-[18rem]   transform hover:scale-105 duration-500 ease-in-out relative mx-auto flex justify-center"
              >
                Update Profile
              </button>
              )}
            </form>
          </div>
        </section>
      </div>
    </>
  );
}

export default EditProfile;
