import React, { useState, useEffect } from "react";
import PallMain from "./HomePAll/PallMain";
import PinputMain from "./HomePinput/PinputMain";
import HomePpostMain from "./HomePpost/HomePpostMain";
import HomePprofilemain from "./HomePprofile/HomePprofilemain";
import Productpage from "./ProductPage/Productpage";
import Profilemain from "../ServicesByINO/FindProduct/Profile/Profilemain";

export default function HomePSliderMain() {
  // const [showHomePost, setShowHomePost] = useState(false);

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  // const tabsData = [
  //   {
  //     label: "ALL",
  //     content: showHomePost ? (
  //       <Productpage onClick={handlereverse} />
  //     ) : (
  //       <PallMain onClick={handleClick} />
  //     ),
  //   },
  //   {
  //     label: "INPUT",
  //     content: <PinputMain />,
  //   },
  //   {
  //     label: "POST",
  //     content: <HomePpostMain />,
  //   },
  //   {
  //     label: "PROFILE",
  //     content: <Profilemain />,
  //   },
  // ];

  // const [activeTabIndex, setActiveTabIndex] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="bg-layout shadow-md drop-shadow-md lg:w-[155vh] mb-[7rem] lg:ml-[268px] relative top-[1rem]">
      
  
      <PallMain />
       
      </div>
    </>
  );
}
