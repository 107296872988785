import React from 'react'
import {MdAddLocationAlt} from "react-icons/md"
import image from "../images/food-c.png"
import imge from "../images/market.png"
import shop from '../images/shop-a.png'

export default function MarketsNearby() {
  return (
    <>
    <div className="border border-gray-400 rounded-md w-[99%] mx-auto mt-9 ">

<div className="flex flex-row justify-start  items-center ml-8 gap-[50rem] p-[11px]">
<h1 className="font-all font-semibold text-[22px]">
  Markets Near by
</h1>
{/*  */}
<span className=' justify-center lg:w-[10rem] flex flex-row items-center hover:bg-[#CB2229]  border border-[#CB2229] hover:text-white p-[4px] bg-gray-100 border-1 font-all rounded-full transform hover:scale-105  duration-500 ease-in-out cursor-pointer'>set location<span><MdAddLocationAlt/></span></span> 
{/*  */}



  </div>
  {/*  */}

<div className="flex flex-wrap  gap-4 mt-4 justify-center mb-6">

<img src={shop} className='h-[224px] rounded-md' alt="" />
<img src={imge} className='h-[224px] rounded-md' alt="" />
<img src={shop} className='h-[224px] rounded-md' alt="" />

</div>

    </div>
    </>
  )
}
