import React, { useState, useEffect } from "react";

import { BsChevronCompactLeft, BsChevronCompactRight } from "react-icons/bs";
import { RxDotFilled } from "react-icons/rx";

function Homeslider() {
  const slides = [
    {
      url: "https://images.unsplash.com/photo-1505740420928-5e560c06d30e?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8aGVhZHBob25lc3xlbnwwfHwwfHx8MA%3D%3D",
    },
    {
      url: "https://images.unsplash.com/photo-1524678606370-a47ad25cb82a?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8aGVhZHBob25lc3xlbnwwfHwwfHx8MA%3D%3D",
    },
    
    {
      url: "https://images.unsplash.com/photo-1613040809024-b4ef7ba99bc3?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    
   
   
  ];
  const [currentIndex, setCurrentIndex] = useState(0);

  const prevSlide = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const nextSlide = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex);
  };

  useEffect(() => {
    const timeout = setTimeout(nextSlide, 3300);
    return () => clearTimeout(timeout);
  }, [currentIndex]);

  return (
    <>
      <div className="max-w-[1520px] h-[362px] w-full m-auto  relative group">
        <div
          style={{ backgroundImage: `url(${slides[currentIndex].url})` }}
          className="w-full h-[362px] xs:h-[12rem] rounded-2xl xs:rounded-none bg-center bg-cover duration-500"
        ></div>
<div className="xs:hidden">
        <div className=" absolute  xs:top-[24%] top-[48%] -translate-x-0 translate-y-[-50%] left-[22.75px] xs:left-[-27px] text-2xl rounded-md p-2 bg-black/20 xs:bg-[#20394b52] xs:p-[0px] xs:h-[2.3rem]  xs:flex xs:items-center   text-white cursor-pointer">
          <BsChevronCompactLeft onClick={prevSlide} size={20} />
        </div>
        {/* Right Arrow */}
        <div className=" absolute  xs:top-[24%] top-[48%] -translate-x-0 translate-y-[-50%] right-[22.75px] xs:right-[-26px] text-2xl rounded-md p-2 bg-black/20  xs:bg-[#20394b52] xs:p-[0px] xs:h-[2.3rem]  xs:flex xs:items-center  text-white cursor-pointer">
          <BsChevronCompactRight onClick={nextSlide} size={20} />
        </div>
        </div>
        <div className="flex top-4 justify-center py-2">
          {slides.map((slide, slideIndex) => (
            <div
              key={slideIndex}
              onClick={() => goToSlide(slideIndex)}
              className="text-2xl cursor-pointer"
            >
              <RxDotFilled className="text-[#CB2229]" />
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default Homeslider;
