import React from 'react'
import reward from "./reward.png";

function WalletReward() {
  return (
    <>
        <div className="flex justify-start lg:ml-[3rem] lg:mt-[3rem]  h-[50%] overflow-y-auto  scrollbar scrollbar-thumb-gray-300 scrollbar-track-gray-100  drop-shadow-lg bg-[white] shadow-md rounded-md w-[50%] ">
          <div className="grid grid-cols-3 gap-4 mx-auto relative top-[1rem]">
            <div className=" rounded-md shadow-2xlflex items-center justify-center">
              <img src={reward} className="h-[168px]" alt="" />
              <h3 className="text-white">hello</h3>
            </div>
            <div className=" rounded-md shadow-2xlflex items-center justify-center">
              <img src={reward} className="h-[168px]" alt="" />
            </div>
            <div className=" rounded-md shadow-2xlflex items-center justify-center">
              <img src={reward} className="h-[168px]" alt="" />
            </div>
            <div className=" rounded-md shadow-2xlflex items-center justify-center">
              <img src={reward} className="h-[168px]" alt="" />
            </div>
            <div className=" rounded-md shadow-2xlflex items-center justify-center">
              <img src={reward} className="h-[168px]" alt="" />
            </div>
            <div className=" rounded-md shadow-2xlflex items-center justify-center">
              <img src={reward} className="h-[168px]" alt="" />
            </div>
            <div className=" rounded-md shadow-2xlflex items-center justify-center">
              <img src={reward} className="h-[168px]" alt="" />
            </div>
            <div className=" rounded-md shadow-2xlflex items-center justify-center">
              <img src={reward} className="h-[168px]" alt="" />
            </div>
            <div className=" rounded-md shadow-2xlflex items-center justify-center">
              <img src={reward} className="h-[168px]" alt="" />
            </div>
            <div className=" rounded-md shadow-2xlflex items-center justify-center">
              <img src={reward} className="h-[168px]" alt="" />
            </div>
            <div className=" rounded-md shadow-2xlflex items-center justify-center">
              <img src={reward} className="h-[168px]" alt="" />
            </div>
            <div className=" rounded-md shadow-2xlflex items-center justify-center">
              <img src={reward} className="h-[168px]" alt="" />
            </div>
            <div className=" rounded-md shadow-2xlflex items-center justify-center">
              <img src={reward} className="h-[168px]" alt="" />
            </div>
            <div className=" rounded-md shadow-2xlflex items-center justify-center">
              <img src={reward} className="h-[168px]" alt="" />
            </div>
            <div className=" rounded-md shadow-2xlflex items-center justify-center">
              <img src={reward} className="h-[168px]" alt="" />
            </div>
            <div className=" rounded-md shadow-2xlflex items-center justify-center">
              <img src={reward} className="h-[168px]" alt="" />
            </div>
            <div className=" rounded-md shadow-2xlflex items-center justify-center">
              <img src={reward} className="h-[168px]" alt="" />
            </div>
            <div className=" rounded-md shadow-2xlflex items-center justify-center">
              <img src={reward} className="h-[168px]" alt="" />
            </div>
            <div className=" rounded-md shadow-2xlflex items-center justify-center">
              <img src={reward} className="h-[168px]" alt="" />
            </div>
            <div className=" rounded-md shadow-2xlflex items-center justify-center">
              <img src={reward} className="h-[168px]" alt="" />
            </div>
            <div className=" rounded-md shadow-2xlflex items-center justify-center">
              <img src={reward} className="h-[168px]" alt="" />
            </div>
          </div>
        </div> 
    </>
  )
}

export default WalletReward
