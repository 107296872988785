import React from "react";

function Contactform() {
  return (
    <>
      <div className="lg:w-[90vh] fade-in-left">
        <h1 className="text-center font-all font-extrabold text-[23px] text-color lg:relative lg:right-[7rem] mt-2 mb-3">
          Contact Us
        </h1>
        <div className="form">
          {/* 01  */}
          <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[32rem] ">
            <label
              htmlFor="input"
              className=" text text-sm  text-black w-[71px] rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content"
            >
              Full Name
            </label>
            <input
              type="text"
              placeholder="Write here..."
              name="input"
              className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px]"
            />
          </div>
          {/* 02  */}
          <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[32rem] ">
            <label
              htmlFor="input"
              className=" text text-sm  text-black w-[6rem] rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content"
            >
              Email Address
            </label>
            <input
              type="text"
              placeholder="Write here..."
              name="input"
              className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px]"
            />
          </div>
          {/* 03  */}
          <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[32rem] ">
            <label
              htmlFor="input"
              className="text text-sm text-black w-[51px] rounded-md bg-white relative top-2.5 ml-2 px-1 w-fit-content"
            >
              Purpose
            </label>
            <select
              name="input"
              className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px]"
            >
              <option value="">Select an option</option>
              <option value="option1">Option 1</option>
              <option value="option2">Option 2</option>
              <option value="option3">Option 3</option>
            </select>
          </div>
          {/* 04  */}
          <div className="cooltextarea flex flex-col w-fit-content static max-w-240 lg:w-[32rem] mb-4">
            <label
              htmlFor="textarea"
              className="text text-sm text-black w-[8rem] rounded-md bg-white relative top-2.5 ml-2 px-1 w-fit-content"
            >
              Leave a Message
            </label>
            <textarea
              placeholder="Write here..."
              name="textarea"
              rows="6"
              className="textarea px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px]"
            />
          </div>
          {/* Butt */}
          <button class="mb-6 bg-[#CB2229] text-white rounded-full font-all p-[6px] lg:mt-[0.5rem] w-[18rem]   transform hover:scale-105 duration-500 ease-in-out relative lg:left-[7rem] ">
            Send Message
          </button>
        </div>
      </div>
    </>
  );
}

export default Contactform;
