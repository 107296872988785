import React from 'react'
import Inputform from './Inputform'

function Inputmain() {
  return (
    <>
     <Inputform/>

    </>
  )
}

export default Inputmain
