import React, { useState } from "react";

import { AiOutlinePlusCircle,AiOutlineHeart,AiFillHeart } from "react-icons/ai";
import { GrLocation } from "react-icons/gr";
export default function CreateCampaignForm() {
  const [sliderValue, setSliderValue] = useState(90);

  const handleSliderChange = (e) => {
    setSliderValue(parseInt(e.target.value));
  };
// 
  return (
    <>
      <div className=" w-[93%] mx-auto p-[5px]">
        <div className="border border-[#cfcaca82] rounded-md w-[68%] mx-auto shadow-sm">
          <h1 className="text-center font-all font-semibold my-[12px] text-[19px]">
            Create a Campaign
          </h1>
          <form action="" className="flex flex-col justify-center items-center">
            {/* item a  */}
            <div className="item-a">
              <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[32rem] ">
                <label
                  htmlFor="input"
                  className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content"
                >
                  Add headline
                </label>
                <input
                  type="text"
                  placeholder="Write here..."
                  name="input"
                  className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px]"
                />
              </div>
              {/* b  */}
              <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[32rem] ">
                <label
                  htmlFor="input"
                  className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content"
                >
                  Add Description
                </label>
                <input
                  type="text"
                  placeholder="Write here..."
                  name="input"
                  className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px]"
                />
              </div>
              {/* c  text-area */}
              <div className="collinpu-text-area--img  flex flex-col w-fit-content static max-w-240 lg:w-[32rem]">
                <label
                  htmlFor="input"
                  className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content"
                >
                  Attach Supporting Document
                </label>
                <div className="mb-4  h-[9rem] input px-2 py-3 text-sm border-[1px] border-[#738482] border-dashed rounded-md focus:border-[1px]">
                  <span className="p-[40px] text-[42px] flex justify-center items-center text-[#D2D2D2]">
                    <AiOutlinePlusCircle />
                  </span>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="item-b  mt-4 mb-2">
          <div className="border border-[#cfcaca82]   rounded-md w-[68%] mx-auto shadow-sm mb-3 ">
            <h1 className="font-all font-semibold text-[19px] text-start ml-6 mt-3 mb-2">
              Select Keywords
            </h1>
            {/* - a  */}
            <div className="coolinput flex mx-auto flex-col w-fit-content static max-w-240 lg:w-[32rem] ">
              <label
                htmlFor="input"
                className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content"
              >
                Add Keywords
              </label>
              <input
                type="text"
                placeholder="Write here..."
                name="input"
                className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px]"
              />
            </div>
            {/* - b  */}
            <div className="coolinput flex mx-auto flex-col w-fit-content static max-w-240 lg:w-[32rem] mb-3 ">
              <label
                htmlFor="input"
                className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content"
              >
                Suggested Keywords
              </label>
              <input
                type="text"
                placeholder="Write here..."
                name="input"
                className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px]"
              />
            </div>
          </div>
        </div>
        <div className="item-c mt-4 mb-2">
          <div className="border border-[#cfcaca82]   rounded-md w-[68%] mx-auto shadow-sm mb-3 ">
            <h1 className="font-all font-semibold text-[19px] text-start ml-6 mt-3 mb-2">
              Advertise Near Location
            </h1>
            {/*  */}
            <div className="--get-location drop-c flex flex-col justify-center rounded-full mx-auto p-[9px] w-[14rem]">
              <span className="flex flex-row items-center justify-center gap-2">
                <GrLocation /> Use Current Location
              </span>
            </div>
            <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[32rem] mx-auto ">
              <label
                htmlFor="input"
                className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content"
              >
                Location
              </label>
              <input
                type="text"
                placeholder="Write here..."
                name="input"
                className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px]"
              />
            </div>
            <div className="w-3/4 mx-auto mt-4 mb-4">
              <label
                htmlFor="range"
                className=" font-all  text-lg font-semibold"
              >
                Selected Distance ( {sliderValue} km):
              </label>
              <input
                type="range"
                id="range"
                name="range"
                min="20"
                max="500"
                value={sliderValue}
                onChange={handleSliderChange}
                className="w-full mt-2 bg-yellow-200"
              />
              <div className="flex justify-between mt-2">
                <span>20 km</span>
                <span>{sliderValue} km</span>
              </div>
            </div>

            {/*  */}
          </div>
          <div className="item-d setbudget border border-[#cfcaca82]   rounded-md w-[68%] mx-auto shadow-sm mb-3 mt-4">
            <h1 class="font-all font-semibold text-[19px] text-start ml-6 mt-3 mb-2">
              Add Budget
            </h1>
            {/* a  */}
            <div className="flex flex-col justify-center items-center">
              <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[32rem] ">
                <label
                  htmlFor="input"
                  className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content"
                >
                  Enter your Budget
                </label>
                <input
                  type="text"
                  placeholder="Write here..."
                  name="input"
                  className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px]"
                />
              </div>
              <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[32rem] ">
                <label
                  htmlFor="input"
                  className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content"
                >
                  Max Bidding amount
                </label>
                <input
                  type="text"
                  placeholder="Write here..."
                  name="input"
                  className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px]"
                />
              </div>
              {/* b  */}
              <div className="flex flex-row items-center justify-start gap-[20rem] ">
                <h1 className="font-all font-semibold flex flex-col">
                  {" "}
                  <span>Current balance</span>
                  200.97$
                </h1>
                <button class="bg-[#cac5c585] shadow-md lg:mx-auto p-[6px] w-[13rem] rounded-full transform hover:scale-105 duration-500 ease-in-out hover:bg-[#CB2229]  hover:text-white">
                  add money
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="--item-d --run add-money">
          <div className="border border-[#cfcaca82]   rounded-md w-[68%] mx-auto shadow-sm mb-3 mt-4">
            <h1 className="font-all font-semibold text-[19px] text-start ml-6 mt-3 mb-2">
              How long will u take to run this ad
            </h1>
            <div className="flex flex-col items-center justify-center gap-[9px]">
              <div className="flex flex-row items-center gap-2">
                <input
                  id="default-radio-1"
                  type="radio"
                  value=""
                  name="default-radio"
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 shark:focus:ring-blue-600 shark:ring-offset-gray-800 focus:ring-2 shark:bg-gray-700 shark:border-gray-600"
                />

                <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[32rem] ">
                  <label
                    htmlFor="input"
                    className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content"
                  >
                    Set date
                  </label>
                  <input
                    type="text"
                    placeholder="Write here..."
                    name="input"
                    className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px]"
                  />
                </div>
              </div>

              <hr className="w-full" />
              <div className="flex flex-row items-center relative right-[11rem] gap-3">
                <input
                  id="default-radio-1"
                  type="radio"
                  value=""
                  name="checked-radio"
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 shark:focus:ring-blue-600 shark:ring-offset-gray-800 focus:ring-2 shark:bg-gray-700 shark:border-gray-600"
                />
                <h6 className="font-all font-semibold gap-3">
                  until the fund = 0
                </h6>
              </div>
            </div>
          </div>
        </div>

        <div className="--item-c--input-form-velow-section">
          <div className="border border-[#cfcaca82]   rounded-md w-[68%] mx-auto shadow-sm mb-3 mt-4">
            <div className="flex flex-col justify-center items-center">
              <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[32rem] ">
                <label
                  htmlFor="input"
                  className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content"
                >
                  VPA Address
                </label>
                <input
                  type="text"
                  placeholder="Write here..."
                  name="input"
                  className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px]"
                />
              </div>
              {/*  */}
              <h1 class="font-all font-semibold text-[14px] text-start ml-6 mt-3 mb-2">
                Billing address
              </h1>
              {/*  */}
              <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[32rem] ">
                <label
                  htmlFor="input"
                  className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content"
                >
                  Country
                </label>
                <input
                  type="text"
                  placeholder="Write here..."
                  name="input"
                  className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px]"
                />
              </div>
              {/*  */}
              <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[32rem] ">
                <label
                  htmlFor="input"
                  className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content"
                >
                  Street Address
                </label>
                <input
                  type="text"
                  placeholder="Write here..."
                  name="input"
                  className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px]"
                />
              </div>
              {/*  */}
              <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[32rem] ">
                <label
                  htmlFor="input"
                  className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content"
                >
                  City
                </label>
                <input
                  type="text"
                  placeholder="Write here..."
                  name="input"
                  className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px]"
                />
              </div>
              {/*  */}
              <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[32rem] ">
                <label
                  htmlFor="input"
                  className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content"
                >
                  state / province
                </label>
                <input
                  type="text"
                  placeholder="Write here..."
                  name="input"
                  className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px]"
                />
              </div>
              {/*  */}
              <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[32rem] ">
                <label
                  htmlFor="input"
                  className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content"
                >
                  Postal Code
                </label>
                <input
                  type="text"
                  placeholder="Write here..."
                  name="input"
                  className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px]"
                />
              </div>
              {/*  */}
              <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[32rem] ">
                <label
                  htmlFor="input"
                  className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content"
                >
                  Tax Status
                </label>
                <input
                  type="text"
                  placeholder="Write here..."
                  name="input"
                  className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px]"
                />
              </div>
              <div className="collinpu-text-area--img  flex flex-col w-fit-content static max-w-240 lg:w-[32rem]">
                <label
                  htmlFor="input"
                  className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content"
                >
                  Billing Address & Text Information
                </label>
                <div className="mb-4  h-[9rem] input px-2 py-3 text-sm border-[1px] border-[#738482] border-dashed rounded-md focus:border-[1px]">
                  <span className="p-[40px] text-[42px] flex justify-center items-center text-[#D2D2D2]">
                    <AiOutlinePlusCircle />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <button class="bg-[#CB2229] text-white rounded-full font-all p-[6px] lg:mt-[1rem] w-[18rem]   transform hover:scale-105 duration-500 ease-in-out mx-auto flex justify-center">
          Confirm and Make Payment
        </button>
      </div>
    </>
  );
}
