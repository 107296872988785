import React, { useEffect, useState } from "react";

import { fetch_referal_code } from "../../api/userRegestration/CollectionForm";

function ReferQRcode() {
  const [jobdetails, setpjobdetails] = useState();
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    fetch_referal_code()
      .then((response) => {
        console.log("API Response:", response);
        setpjobdetails(response.result);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);
  const handleCopyCode = () => {
    const textField = document.createElement("textarea");
    textField.innerText = jobdetails.referralCode;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    document.body.removeChild(textField);
    setIsCopied(true);
  };
  return (
    <>
      <div className="fade-in-left flex flex-col items-center border-[1px] border-[#D2D2D2]  rounded-md lg:p-[28px] lg:mt-[4rem] lg:mb-[7rem]">
        <h1 className=" font-all font-extrabold text-[23px] text-color lg:relative mb-4">
          Refer to a Friend !
        </h1>
        <div className="text-top">
          <h6 className="font-all text-center  text-[#000000]">
            Invite your friend to INO
          </h6>
          <h6 className="font-all  text-[#000000] text-center">
            Earn Rewards and Points 10 for each referal sent !
          </h6>
          {/* <div className="text-referal">
            <h3 className="text-[#000000] text-center font-all  mt-1">
              Here is you Referal link
            </h3>
            <h4 className="text-[#4460A0] fot-all relative text-center mt-1">
              https://www.dummylinkINO.com
            </h4>
          </div> */}
          <div className="qr-code">
            <h3 className="text-[#000000] font-all text-center mt-1">
              {" "}
              Here is your Referalcode{" "}
            </h3>
            <div className="icong-image flex justify-center ">
              {/* <img
                src="https://i.pinimg.com/564x/cd/c7/3b/cdc73bef50d55c07511f10fbf67fa47c.jpg"
                className="lg:h-[8pc]  rounded-md"
                alt=""
              /> */}

              <div className="shadow-lg font-all font-semibold p-[20px] drop-shadow border rounded-md my-6 text center">
                {jobdetails ? (
                  <div>
                    <p
                      className="tracking-[3px]
"
                    >
                      {jobdetails.referralCode}
                    </p>
                  </div>
                ) : (
                  // Content to render when jobdetails is not available
                  <div role="status">
                    <svg
                      aria-hidden="true"
                      class="w-8 h-8 mr-2 text-gray-200 animate-spin fill-blue-600"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                  </div>
                )}
              </div>
            </div>
            <div className="button-action flex flex-col items-center ">
              <button
                onClick={handleCopyCode}
                className="bg-[#CB2229] text-white rounded-full font-all p-[6px] lg:mt-[0.5rem] w-[18rem] transform hover:scale-105 duration-500 ease-in-out relative"
              >
                {isCopied ? "Code Copied!" : "Copy Code"}
              </button>
              <button class="mb-6 border-[1px] border-[#CB2229] text-black rounded-full font-all p-[6px] lg:mt-[0.5rem] w-[18rem]   transform hover:scale-105 duration-500 ease-in-out relative  ">
                Share code
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ReferQRcode;
