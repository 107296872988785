import React from "react";
import map from "../../staticimages/map.png";
function Map() {
  return (
    <>
      <div className=" shadow-l bg-[#ffff] rounded-md">
        <h2 className="font-all font-bold text-[16px] ml-4 relative top-3">
          Find aroud me
        </h2>
        <p className="font-all relative lg:top-[1rem] lg:left-[30px] inline-block">
          Quickly found Vendor and Buyer near you
        </p>

        <figure className="px-10 pt-10">
          <img
            src={map}
            alt="Shoes"
            className="rounded-md w-[21rem] h-[165px] "
          />
        </figure>
        <div className="card-body items-center text-center">
          <button
            className="relative left-[4rem] top-[-58px]  hover:bg-[#CB2229]  hover:text-white p-[6px] bg-[#F8F8F8] border-1 font-all rounded-full transform hover:scale-105  duration-500 ease-in-out"
            style={{ border: "1px solid #D2D2D2;" }}
          >
            View Full Map
          </button>

          <button
            className="opacity-0 p-[1px] bg-[#F8F8F8] border-1 font-all rounded-full "
            style={{ border: "1px solid #D2D2D2;" }}
          >
            View Full Map
          </button>
        </div>
      </div>
    </>
  );
}

export default Map;
