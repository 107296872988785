import React, { useState, useRef } from 'react';
import { FaPlay, FaPause } from 'react-icons/fa';

const VideoPlayer = () => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayPauseClick = () => {
    const video = videoRef.current;

    if (isPlaying) {
      video.pause();
    } else {
      video.play();
    }

    setIsPlaying(!isPlaying);
  };

  return (
    <div className="mt-4 relative mb-[5rem]">
      <video className="w-[80%] mx-auto shadow-lg" ref={videoRef} autoPlay muted>
        <source
          src="https://v3.cdnpk.net/videvo_files/video/free/2018-04/large_preview/180301_09_A_KowloStreets_31.mp4"
          type="video/mp4"
        />
      </video>
      <button
        className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-gray-800 text-white rounded-full p-3 focus:outline-none hover:bg-gray-600 transition"
        onClick={handlePlayPauseClick}
      >
        {isPlaying ? <FaPause /> : <FaPlay />}
      </button>
    </div>
  );
};

export default VideoPlayer;
