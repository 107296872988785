import React, { useEffect, useState } from "react";
import { getAllSellerServiceList } from "../../../../../api/userRegestration/CollectionForm";
import { useNavigate } from "react-router-dom";


function ExploreService() {
  const accessToken = localStorage.getItem("token");
  const [Product, setProduct] = useState([]);
  const [visibleProducts, setVisibleProducts] = useState(3); 

  useEffect(() => {
    getAllSellerServiceList(accessToken)
      .then((result) => {
        setProduct(result);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [accessToken]);
  // 
const navigate = useNavigate()
 
const handleroute = (serviceId) => {
  navigate(`/exploreService/Services_Buyer/Service_Details/${serviceId}`);
};
const handleSeeMore = () => {
  // Increase the number of visible products by 4 on each "See more" click
  setVisibleProducts((prevVisibleProducts) => prevVisibleProducts + 4);
};
  return (
    <>
      <div
        className="flex items-center bg-gray-100 justify-center mt-10 rounded w-[93%]
          mx-auto p-[9px] "
        style={{ border: "1px solid #D2D2D2" }}
      >
        <div className="flex flex-col gap-4 mb-8">
          <h1 className="font-all font-bold text-start -auto text-[21px] lg:mt-[27px] mb-6">
            Services Seller near you{" "}
          </h1>
          <div className="grid grid-cols-2 gap-4">
          {Product.slice(0, visibleProducts).map((item) => (
              <div
                className="flex w-full max-w-[48rem] bg-white rounded-xl bg-clip-border text-gray-700 shadow-md"
                key={item._id}
              >
                <div  className="cursorpointer m-0 w-1/2 overflow-hidden rounded-xl bg-white bg-clip-border text-gray-700">
                  <img
                    src={item.mediaUrl}
                    alt=""
                    className="h-full w-full object-cover"
                  />
                </div>

                <div className="p-6 w-1/2">
                  {/* <h6 className="mb-4 block font-sans text-base font-semibold uppercase leading-relaxed tracking-normal text-pink-500 antialiased">
          verified
        </h6> */}
                  <h4 className="mb-2 block font-sans text-2xl font-semibold leading-snug tracking-normal text-blue-gray-900 antialiased">
                    {item.serviceName}
                  </h4>
                  <div className="mb-8 block font-sans text-base font-normal leading-relaxed text-gray-700 antialiased italic">
                    {item.details.split(" ").slice(0, 7).join(" ")}...
                  </div>

                  <div className="flex flex-row item-center">
                    <button
                      className="flex select-none items-center gap-2 rounded-lg py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-gray-500 transition-all hover:bg-gray-500/10 active:bg-gray-500/30 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                      type="button"
                    >
                      {item.rate}
                    </button>
                    <button onClick={() => handleroute(item._id)} 
                      className="flex select-none items-center text-center gap-2 rounded-lg py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-gray-500 transition-all bg-gray-500/10  disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                      type="button"
                    >
                      view
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={2}
                        stroke="currentColor"
                        aria-hidden="true"
                        className="h-4 w-4"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {visibleProducts < Product.length && (
            <h1
              className="mt-2 cursor-pointer underline-offset-4 text-center font-all hover:text-blue-600 w-[12%] mx-auto"
              onClick={handleSeeMore}
            >
              See more
            </h1>
          )}
        </div>
      </div>
    </>
  );
}

export default ExploreService;
