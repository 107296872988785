import React from 'react'
import WalletReward from './WalletReward'
import TotallRewards from './TotallRewards'
function TransferMain() {
  return (
    <>
<div className="bg-layout  lg:w-[152vh] lg:h-[77vh] lg:ml-[17rem]  relative top-[1rem] swing-in-top-fwd drop-shadow-lg">


<div className="flex flex-row items-center h-[117vh] lg:relative lg:top-[-14rem] gap-[4rem]">

<WalletReward/>

<TotallRewards/>

</div>


</div>


    </>
  )
}

export default TransferMain
