import React from 'react'
import banner from "../images/banner.png"
import MarketsNearby from './MarketsNearby';
import MostPopular from './MostPopular';
import Recommended from './Recommended';

export default function CatrogrizeAllMain() {
  return (
  <>
  <div className="--banner--img mt-4 mb-2">
 <img src={banner} alt="" />
  </div>
  <MarketsNearby/>
  <MostPopular/>
  <Recommended/>
  </>
  )
}
