import React from 'react'
import Slider from './Slider'
import MarketsNearby from './MarketsNearby'
import MostPopular from './MostPopular'
import Recommende from './Recommende'

export default function MarketTrendAllMain() {
  return (
    <>
    
    <div className="div_Market">
      <div className="m m-2">
      <div class="flex items-center justify-center h-48 mb-8 ">
            <Slider />
          </div>

          <div
            class="mt-[13rem] flex items-center justify-center h-48 rounded  lg:h-[24rem] "
            style={{ border: "1px solid #D2D2D2" }}
          >
            <div className="w-[140vh]">
              <MarketsNearby />
            </div>
          </div>


          <div
            class="mt-[2rem] flex items-center justify-center h-48 rounded  lg:h-[24rem] "
            style={{ border: "1px solid #D2D2D2" }}
          >
            <div className="w-[140vh]">
              <MostPopular />
            </div>
          </div>

          <div
            class="mt-[2rem] flex items-center justify-center h-48 rounded  lg:h-[24rem] "
            style={{ border: "1px solid #D2D2D2" }}
          >
            <div className="w-[140vh]">
              <Recommende />
            </div>
          </div>
      </div>
    </div>
    
    
    
    
    </>
  )
}
