import React from 'react'

export default function UpdateInput({toggle}) {
  return (
  <>
  
<p onClick={toggle}>knwkdfnwkjf,mnwkjfbmwf</p>
  </>
  )
}
