import React from "react";
import CampaignProductDetails from "./CampaignProductDetails";
import CreateCampaignForm from "./CreateCampaignForm";
import { BsArrowLeft } from "react-icons/bs";
import { Link } from "react-router-dom";
export default function CampaignMainPage() {
  return (
    <>
  
      <div
        className="bg-layout drop-shadow-md lg:w-[152vh]   lg:ml-[17rem] relative top-[1rem] swing-in-top-fwd
      "
      >
        <div className="flex flex-col gap-2">
          <CampaignProductDetails />
          <CreateCampaignForm />
        </div>
      </div>
    </>
  );
}
