import React from 'react'

export default function Recommended() {
  return (
<>
<div className="border border-gray-400 rounded-md w-[99%] mx-auto mt-9 ">

<div className="flex flex-row justify-start  items-center ml-8 gap-[50rem] p-[11px]">
<h1 className="font-all font-semibold text-[22px]">
  Recommended
</h1>
{/*  */}
{/*  */}



  </div>
  {/*  */}

<div className="flex flex-wrap  gap-4 mt-4 justify-center mb-6">

<img src='https://img.freepik.com/premium-photo/interior-contemporary-clothing-store-with-clothes_274679-21181.jpg?size=626&ext=jpg' className='h-[224px] rounded-md' alt="" />
<img src='https://t3.ftcdn.net/jpg/00/82/84/06/240_F_82840627_EcK9x1B7GUPH0jaYJyNMAUOlw41P97CE.jpg' className='h-[224px] rounded-md' alt="" />
<img src='https://img.freepik.com/premium-photo/interior-shot-racks-with-shirts-undershirts-jeans_88135-5868.jpg?w=1060' className='h-[224px] rounded-md' alt="" />

</div>

    </div>


</>
  )
}
