import React from "react";
import ReferQRcode from "./ReferQRcode";
import ReferOtp from "./ReferOtp";
function Referalstock() {
  return (
    <>
    <div className="md:p-0 lg:p-0 p-[6px]">
      <div className="flex   flex-col md:flex-row  justify-center bg-layout drop-shadow-md lg:w-[152vh] lg:ml-[17rem] relative top-[1rem] swing-in-top-fw md:gap-[9rem] gap-6 md:p-0 sm:p-9">
        <ReferQRcode />
        <ReferOtp />
      </div>
      </div>
    </>
  );
}

export default Referalstock;
