import React from "react";
import banner from "../images/banner.png";
import GovtProjectCard from "./GovtProjectCard";
import GovtOtherProjects from "./GovtOtherProjects";
export default function GovMain() {
  return (
    <>
      <div className="--banner-image">
        <img src={banner} className="mt-4 mb-3" alt="" />
      </div>

      <div className="flex justify-center">
        <button class=" mt-[3rem] mb-6 bg-[#CB2229] text-white rounded-full font-all p-[6px]  w-[18rem]   transform hover:scale-105 duration-500 ease-in-out ">
          Directory
        </button>
      </div>
      <GovtProjectCard/>
      <GovtOtherProjects/>
    </>
  );
}
