// MultiSelectDropdown.js

import React, { useState } from 'react';

const MultiSelectDropdown = ({  }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const options = [
    { value: 'option1', label: 'Option 1' },
    { value: 'option2', label: 'Option 2' },
    { value: 'option3', label: 'Option 3' },
    // Add more options as needed
  ];
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOptions((prevSelected) =>
      prevSelected.includes(option)
        ? prevSelected.filter((selectedOption) => selectedOption !== option)
        : [...prevSelected, option]
    );
  };

  return (
    <div className="relative inline- text-left flex justify-center items-center">
      {/* kjsdnkjsdskjdskjdskjdskjdskjdskjds */}
      <div>
        <button
          type="button"
          onClick={handleToggle}
          className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 focus:outline-none focus:border-blue-300 focus:ring focus:ring-blue-200 active:bg-gray-50 active:text-gray-800"
        >
          {selectedOptions.length > 0
            ? selectedOptions.map((option) => option.label).join(', ')
            : 'Select Options'}
        </button>
      </div>
      {isOpen && (
        <div className="  w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
          <div
            className="py-1"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            {options.map((option) => (
              <div
                key={option.value}
                onClick={() => handleOptionClick(option)}
                className={`block px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-100 ${
                  selectedOptions.includes(option)
                    ? 'bg-blue-500 text-white'
                    : ''
                }`}
                role="menuitem"
              >
                {option.label}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default MultiSelectDropdown;
