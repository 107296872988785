import React from 'react'
import money from "../home/staticimages/money.png"
function WalletTable() {
  return (
    <>
  
      
      <div className="  mx-auto overflow-auto">
        <table className="table-auto  lg:w-[90vh] text-left whitespace-no-wrap">
          <thead>
            <tr>
              <th className="px-4 py-3 title-font tracking-wider font-medium text-black text-sm bg-[#E98F92] rounded-tl rounded-bl">
             
              </th>
              <th className="px-4 py-3 title-font tracking-wider font-medium text-black text-sm bg-[#E98F92]">
               Amount
              </th>
              <th className="px-4 py-3 title-font tracking-wider font-medium text-black text-sm bg-[#E98F92]">
               Date
              </th>
              <th className="px-4 py-3 title-font tracking-wider font-medium text-black text-sm bg-[#E98F92]">
               Invoice
              </th>
             
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className=" flex flex-row  gap-[1rem] px-4 py-3 bg-[#F0EAEA]"><img className='h-[28px]'  src={money} alt="" /> ino deposit</td>
              <td className="px-4 py-3 bg-[#F0EAEA]">5 Mb/s</td>
              <td className="px-4 py-3 bg-[#F0EAEA]">31 march 2023</td>
              <td className="px-4 py-3 bg-[#F0EAEA] text-lg text-black">            view receipt</td>
               
            </tr>
            <tr>
                   <td className=" flex flex-row  gap-[1rem] px-4 py-3 "><img className='h-[28px]'  src={money} alt="" /> ino deposit</td>
              <td className="     px-4 py-3">25 Mb/s</td>
              <td className="     px-4 py-3">31 march 2023</td>
              <td className="     px-4 py-3 text-lg text-black">
              view receipt
              </td>
             
            </tr>
            <tr>
                   <td className=" flex flex-row  gap-[1rem] px-4 py-3 bg-[#F0EAEA]"><img className='h-[28px]'  src={money} alt="" /> ino deposit</td>
              <td className="  bg-[#F0EAEA]   px-4 py-3">36 Mb/s</td>
              <td className="  bg-[#F0EAEA]   px-4 py-3">31 march 2023</td>
              <td className="  bg-[#F0EAEA]   px-4 py-3 text-lg text-black">
              view receipt
              </td>
             
            </tr>
            <tr>
                  <td className=" flex flex-row  gap-[1rem] px-4 py-3"><img className='h-[28px]'  src={money} alt="" /> ino deposit</td>
              <td className="   px-4 py-3">
                48 Mb/s
              </td>
              <td className="    px-4 py-3">
                31 march 2023
              </td>
              <td className="   px-4 py-3 text-lg text-black">
            view receipt
              </td>
              
            </tr>
            <tr>
                   <td className=" flex flex-row  gap-[1rem] px-4 py-3 bg-[#F0EAEA]"><img className='h-[28px]'  src={money} alt="" /> ino deposit</td>
              <td className="  bg-[#F0EAEA]   px-4 py-3">36 Mb/s</td>
              <td className="  bg-[#F0EAEA]   px-4 py-3">31 march 2023</td>
              <td className="  bg-[#F0EAEA]   px-4 py-3 text-lg text-black">
              view receipt
              </td>
             
            </tr>
            <tr>
                  <td className=" flex flex-row  gap-[1rem] px-4 py-3"><img className='h-[28px]'  src={money} alt="" /> ino deposit</td>
              <td className="   px-4 py-3">
                48 Mb/s
              </td>
              <td className="    px-4 py-3">
                31 march 2023
              </td>
              <td className="   px-4 py-3 text-lg text-black">
            view receipt
              </td>
              
            </tr>


            <tr>
                   <td className=" flex flex-row  gap-[1rem] px-4 py-3 bg-[#F0EAEA]"><img className='h-[28px]'  src={money} alt="" /> ino deposit</td>
              <td className="  bg-[#F0EAEA]   px-4 py-3">36 Mb/s</td>
              <td className="  bg-[#F0EAEA]   px-4 py-3">31 march 2023</td>
              <td className="  bg-[#F0EAEA]   px-4 py-3 text-lg text-black">
              view receipt
              </td>
             
            </tr>
            <tr>
                  <td className=" flex flex-row  gap-[1rem] px-4 py-3"><img className='h-[28px]'  src={money} alt="" /> ino deposit</td>
              <td className="   px-4 py-3">
                48 Mb/s
              </td>
              <td className="    px-4 py-3">
                31 march 2023
              </td>
              <td className="   px-4 py-3 text-lg text-black">
            view receipt
              </td>
              
            </tr>
          </tbody>
        </table>
      </div>
     
  

    </>
  )
}

export default WalletTable
