import React, { useState, useEffect } from "react";

import Logo from "../../assets/logo/Logo-Trans.png";
import image from "../../assets/logo/auth.png";
import { Link } from "react-router-dom";
import GifComponent from "./GifComponent";

export default function WelcomeAuth() {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000); 
  }, []);
  return (
    <>
      {isLoading ? (
        <GifComponent />
      ) : (
       
        <div className="lg:overflow-hidden">
          <div className="flex flex-row justify-start  ">
            <div className=" w-[100%] h-[100%] item-1 lg:h-screen lg:w-full bg-[#cb22290f] xs:min-h-screen  ">
              <div className="flex flex-col justify-center items-center mt-4 xs:mt-8 gap-[12px]">
                <img
                  src={Logo}
                  alt=""
                  className="lg:w-[112px] xs:h-[49px] bounce-in-top  "
                />
              </div>
              <div className="text">
                <h1 className="text-center font-all font-extrabold lg:text-[35px]  xs:text-[24px]  leading-[70px] text-[#000000] ">
                  Welcome to INO !
                </h1>
              </div>
              <div className="image relative top-[14px] drop-auth mx-auto lg:w-[23%]  w-[79%] bg-white rounded-[33px]">
                <img
                  src={image}
                  className="mx-ato m-auto lg:h-[21rem] image-coin  "
                  alt=""
                />
              </div>

              <p className="font-all xs:italic lg:w-[55rem] mx-auto lg:mt-[3rem] xs:mt-8  xs:text-[13px] xs:p-[13px] xs:text-center">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta
                corrupti inventore voluptatum molestias culpa quam facere,
              </p>

              <p className="font-all lg:mt-5 text-center xs:text-[13px] xs:p-[13px]">
                Tap 'Agree & Continue' to procceed after reveiwing the INO
                <Link to="/auth/to">
                  <span
                    className="font-all underline-offset-2 text-[#cb2229] cursor-pointer"
                    style={{ textDecoration: "underline" }}
                  >
                    Terms of Services
                  </span>
                </Link>
              </p>
              <Link to="/auth/verification">
                <button class="flex justify-center fade-in-left bg-[#CB2229] text-white rounded-full font-all lg:p-[6px] xs:p-1  xs:mt-4 xs:w-[60%] lg:mt-[2.4rem] w-[18rem]   transform hover:scale-105 duration-500 ease-in-out mx-auto">
                  Agree and Continue
                </button>
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
