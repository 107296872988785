import React from 'react'
import Buttonstore from './Buttonstore'


function Buttonsmain() {
  return (
    <>
   <div className="flex flex-row gap-3 justify-center items-center">
    <Buttonstore/>

    </div>   
    </>
  )
}

export default Buttonsmain
