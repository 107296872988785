import React from "react";
import { NavLink } from "react-router-dom";
import { HiArrowLongLeft } from "react-icons/hi2";

import ExploreCarousel from "./ExploreCarousel";
import ExploreService from "./ExploreService";
import ExploreCommunityPost from "./ExploreCommunityPost";
function ExploreAlllmain() {
  return (
    <>
      <div class="flex items-center justify-center h-48 mb-8 ">
        <ExploreCarousel />
      </div>
      <div className="flex flex-row items-center">
        <NavLink to="/">
          <div
            className=" lg:relative  lg:w-[100%]  lg:left-[1rem]    flex flex-row item-center justify-start gap-3 z-10 top-[11rem]"
            style={{ alignItems: "center" }}
          >
            <span className="text-[32px] text-[#4b5563]">
              <HiArrowLongLeft />
            </span>
            <h1 className="font-semibold  text-[#4b5563] text-[20px]">
              Go Back to home page
            </h1>
          </div>
        </NavLink>
      </div>
      <div className="mt-[13rem]">
        <ExploreService />
      </div>

      <div className="mb-8">
        <ExploreCommunityPost />
      </div>
    </>
  );
}

export default ExploreAlllmain;
