import React from "react";

function Termstock() {
  return (
    <>
      <div className="swing-in-top-fwd">
        <div class="container   bg-layout drop-shadow-md w-[83%] mx-auto relative left-[7rem] top-[4rem]">
          <div class=" w-[83%] mx-auto ">
            <h1 class="text-center text-[20px] mt-1 font-extrabold font-all title-font mb-4 text-gray-900">
              {" "}
              Terms OF Service
            </h1>

            <p className="text-center font-all font-semibold">
              Welcome to Privacy Section of our website,By using our Website,You
              abide to agree on our terms and condition which are as follow
            </p>

            <div className="terms mt-4 ">
              <h1 className="font-semibold  flex justify-start">
                1.{" "}
                <span className="font-semibold ml-1">
                  {" "}
                  Acceptance of Terms :
                </span>
              </h1>
              <p
                className="mt-2 font-all ml-1
      "
              >
                Acceptance of Terms: By accessing or using our website, you
                explicitly acknowledge and agree to these terms and conditions.
                If you disagree with any of the terms mentioned here, you should
                refrain from using our website.{" "}
              </p>
            </div>
            {/* 2  */}
            <div className="terms mt-4 ">
              <h1 className="font-semibold  flex justify-start">
                2.{" "}
                <span className="font-semibold ml-1">
                  {" "}
                  Limitations of Liability :
                </span>
              </h1>
              <p
                className="mt-2 font-all ml-1
      "
              >
                We do not accept any liability for the content, accuracy, or
                quality of the information provided on our website. We reserve
                the right to remove, modify, or make changes to the content of
                our website at any time, and without notice.
              </p>
            </div>
            {/* 3 */}
            <div className="terms mt-4 ">
              <h1 className="font-semibold  flex justify-start">
                3.{" "}
                <span className="font-semibold ml-1">
                  {" "}
                  Intellectual Property Rights :
                </span>
              </h1>
              <p
                className="mt-2 font-all ml-1
      "
              >
                The content of our website is protected by copyright laws and
                other proprietary rights. You may not copy, reproduce, or
                distribute any of our content without our explicit permission.
              </p>
            </div>
            {/* 4  */}
            <div className="terms mt-4 ">
              <h1 className="font-semibold  flex justify-start">
                4.{" "}
                <span className="font-semibold ml-1">
                  {" "}
                  Third-Party Websites :
                </span>
              </h1>
              <p
                className="mt-2 font-all ml-1
      "
              >
                Our website may contain links to third-party websites that are
                not owned or operated by us. We do not have control over these
                websites, and therefore, we are not responsible for the content
                or accuracy of these websites.
              </p>
            </div>
            {/* 5 */}
            <div className="terms mt-4 ">
              <h1 className="font-semibold  flex justify-start">
                5.{" "}
                <span className="font-semibold ml-1">
                  {" "}
                  Indemnification :
                </span>
              </h1>
              <p
                className="mt-2 font-all ml-1
      "
              >
           You agree to indemnify us and hold us harmless from any claims, costs, or damages incurred due to your use of  our website.
              </p>
            </div>
            {/* 6  */}
            <div className="terms mt-4 ">
              <h1 className="font-semibold  flex justify-start">
                6.{" "}
                <span className="font-semibold ml-1">
                  {" "}
                  Governing Law :
                </span>
              </h1>
              <p
                className="mt-2 font-all ml-1
      "
              >
           These terms and conditions shall be governed by and construed in accordance with the laws of the country of our company's registration.
              </p>
            </div>
            {/*7  */}
            <br />
            <p className="font-all mb-2
            ">
We reserve the right to modify or update these terms and conditions without prior notice. By continuing to use our website after any updates, you acknowledge and agree to these changes.</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Termstock;
