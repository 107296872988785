import React from 'react'
import img from "../images/mandi-rate.png";
import rate from "../images/mandi-list.png"
export default function MandiPrices() {
  return (
    <>
      <div className="--banner mt-4 mb-4">
        <img src={img} alt="" />
      </div>
<hr className="w-full border-gray-400 mb-2" />
<h1 className="text-center font-all font-semibold text-[25px]">Mandi Rate</h1>
<hr className="w-full border-gray-400 mb-2 mt-2" />
      
<div className="">

<img src={rate} className='h-[25rem] mx-auto rounded-md' alt="" />
<hr className="w-full border-gray-400 mb-6 mt-2" />

</div>

    </>
  )
}
