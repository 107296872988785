import React from 'react'
import { IoFilter } from 'react-icons/io5'

function SearchFilter() {
  return (
    <>
    <form>
<div className="relative top-[12px]">

  <label
    htmlFor="default-search"
    className="mb-2 text-sm font-medium text-gray-900 sr-only shak:text-white"
  >
    Search
  </label>
<div className="ce_09901 mx-auto justify-center flex">
  <div className="relative">
    <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
      <svg
        className="w-4 h-4 text-gray-500 shak:text-gray-400"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 20 20"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
        />
      </svg>
    </div>
    <input
      type="search"
      id="default-search"
      className=" w-[20rem] bg-[#f3f4f6] block w- p-2 h-[2.5rem] ps-10 text-sm text-gray-900 border border-gray-300 rounded-[8px] focus:ring-blue-500 focus:border-blue-500 shak:bg-gray-700 shak:border-gray-600 shak:placeholder-gray-400 shak:text-white shak:focus:ring-blue-500 shak:focus:border-blue-500"
      placeholder="search"
      required=""
    />
    {/* <button
      type="submit"
      className="text-white  bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium shak text-sm px-4 py-2 shak:bg-blue-600 shak:hover:bg-blue-700 shak:focus:ring-blue-800"
    >
      Search
    </button> */}
    <IoFilter className='absolute end-2.5 bottom-2.5' />
  </div>
  </div>

  </div>
</form>

    </>
  )
}

export default SearchFilter