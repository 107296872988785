import React, { useRef } from "react";

import home from "../staticimages/home.png";
import Explore from "../staticimages/explore.png";
import Product from "../staticimages/addproduct.png";
import ervice from "../staticimages/listservice.png";
import Post from "../staticimages/jobpost.png";
import Member from "../staticimages/member.png";
import laptop from "../staticimages/laptop.png";
import { Link, NavLink } from "react-router-dom";
import { Card } from "flowbite-react";
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import img from "../../../assets/img-card/images-card.png";
import img2 from "../../../assets/img-card/2.png";
function Services() {
;
  const contentRef = useRef(null);

  const scrollLeft = () => {
    if (contentRef.current) {
      contentRef.current.scrollLeft -= 400;
    }
  };

  const scrollRight = () => {
    if (contentRef.current) {
      contentRef.current.scrollLeft += 400;
    }
  };

  return (
    <>
      <div className="item-desktop xs:hidden ">
        <div className="flex flex-col gap-[17px] xs:mt-[18rem]">
          <div className="flex flex-row gap-4">
            <NavLink to="/findproducts">
              {" "}
              <div className="bg-white shadow-l text-center lg:h-[183px] lg:w-[197px] rounded-md  cursor-pointer  transform hover:scale-105 duration-500 ease-in-out">
                <div className="img">
                  <img src={home} className=" xs:h-[86px] " alt="" />
                </div>
                <h3 className="text-center font-semibold font-all mt-[12px]">
                  Find Product
                </h3>
              </div>
            </NavLink>

            <NavLink to="exploreService">
              <div className="bg-white shadow-l text-center lg:h-[183px] lg:w-[197px] rounded-md  cursor-pointer  transform hover:scale-105 duration-500 ease-in-out">
                <div className="img">
                  <img src={Explore} className=" xs:h-[86px] " alt="" />
                </div>
                <h3 className="text-center font-semibold font-all mt-[12px]">
                  Explore Service
                </h3>
              </div>
            </NavLink>

            <NavLink to="findjobs">
              <div className=" bg-white shadow-l text-center lg:h-[183px] lg:w-[197px] rounded-md  cursor-pointer  transform hover:scale-105 duration-500 ease-in-out">
                <div className="img ">
                  <img
                    className="rounded-t-lg h-[137px] xs:h-[86px] w-full"
                    src="https://img.freepik.com/free-psd/3d-illustration-people-with-gadget-use-highspeed-internet_1150-65912.jpg?w=740&t=st=1687385575~exp=1687386175~hmac=89e62bb3671514fe3d283d059be93262df0c0d67d6a659852fb232fb88f2c2c4"
                    alt=""
                  />
                </div>
                <h3 className="text-center font-semibold font-all mt-[12px]">
                  Find Jobs
                </h3>
              </div>
            </NavLink>
          </div>

          <div className="flex flex-row gap-4">
            <NavLink to="addProject">
              <div className="bg-white shadow-l text-center lg:h-[183px] lg:w-[197px] rounded-md  cursor-pointer  transform hover:scale-105 duration-500 ease-in-out">
                <div className="img">
                  <img src={Product} alt="" />
                </div>
                <h3 className="text-center font-semibold font-all mt-[12px]">
                  Add Product
                </h3>
              </div>
            </NavLink>

            <NavLink to="/ListProject">
              <div className="bg-white shadow-l text-center lg:h-[183px] lg:w-[197px] rounded-md  cursor-pointer  transform hover:scale-105 duration-500 ease-in-out">
                <div className="img">
                  <img src={ervice} alt="" />
                </div>
                <h3 className="text-center font-semibold font-all mt-[12px]">
                  List Your Service
                </h3>
              </div>
            </NavLink>

            <Link to="/PostJob">
              <div className="bg-white shadow-l text-center lg:h-[183px] lg:w-[197px] rounded-md  cursor-pointer  transform hover:scale-105 duration-500 ease-in-out">
                <div className="img">
                  <img src={Post} alt="" />
                </div>
                <h3 className="text-center font-semibold font-all mt-[12px]">
                  Post Job
                </h3>
              </div>
            </Link>
          </div>
          <div className="flex flex-row gap-[19px]">
            <div className=" text-center lg:h-[auto] lg:w-[406px] rounded-md">
              <div className="img">
                <img src={Member} className="rounded-t-md" alt="" />
              </div>
              <Link to="/register/resellerForm">
                <button className="bg-[#CB2229] text-white rounded-full font-all p-[6px] lg:mt-[1rem] w-[18rem]   transform hover:scale-105 duration-500 ease-in-out ">
                  Become a reseller and earn
                </button>
              </Link>
            </div>
            <Link to="PostProject">
              <div className="bg-white shadow-l text-center lg:h-[183px] lg:w-[197px] rounded-md  cursor-pointer  transform hover:scale-105 duration-500 ease-in-out">
                <div className="img">
                  <img src={laptop} alt="" />
                </div>
                <h3 className="text-center font-semibold font-all mt-[12px]">
                  Post Project
                </h3>
              </div>
            </Link>
          </div>
        </div>
      </div>
      {/* mobile item  */}

    </>
  );
}

export default Services;
