import React, { useEffect, useState } from "react";
import axios from "axios";
import { GoVerified } from "react-icons/go";
import { useWallet } from '../../../../Wallet/Context/WalletContext';
function CommunityPost() {

  const { postlist } = useWallet();



// 




useEffect(() => {
  const currentDate = new Date();
  console.log(`post rendered at: ${currentDate}`);
}, []); 

// 
return (
    <>
      <div
        className="flex items-center justify-center mt-10 rounded w-[93%]
          mx-auto "
        style={{ border: "1px solid #D2D2D2" }}
      >
        <div className="flex flex-col gap-4 mb-8">
          <h1 className="font-all font-bold text-start -auto text-[21px] lg:mt-[27px] mb-6">
            All Community Posts
          </h1>
          <section
  id="Projects"
  className="w-fit mx-auto grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 justify-items-center justify-center gap-y-20 gap-x-14 mt-10 mb-5"
>
  {postlist.map((item, index) => (
    <div
      key={index}
      className="w-72 bg-white shadow-md rounded-xl duration-500 hover:scale-105 hover:shadow-xl"
    >
      <a href="#">
        <img
          src={item.mediaUrl}
          alt="img not found "
          className="h-80 w-72 object-cover rounded-t-xl"
        />
        <div className="px-4 py-3 w-72">
          <span className="text-gray-400 mr-3 uppercase text-xs">
            post tile
          </span>
          <p className="text-lg font-bold text-black truncate block capitalize">
            {item.postTitle}
          </p>
          <span className="text-gray-400 mr-3  mt-3 uppercase text-xs">
            post description
          </span>
          <div className="flex items-center">
            <p className="text-lg font-semibold text-black cursor-auto my-2">
            {item.details}
            </p>
            
          </div>
        </div>
      </a>
    </div>
  ))}
</section>

          <h1 className=" mt-2 cursor-pointer underline-offset-4 text-center font-all    hover:text-blue-600 w-[12%] mx-auto">
            See more
          </h1>
        </div>
      </div>
    </>
  );
}

export default CommunityPost;
