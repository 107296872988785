import { click } from '@testing-library/user-event/dist/click';
import React from 'react';
import { BsPencil } from 'react-icons/bs';
import { MdDeleteOutline } from 'react-icons/md';

export default function ActionButton({ remove, edit }) {
  const handleEditClick = () => {
    // Add the logic you want to execute on edit click

    console.log('Edit button clicked');
  };

  return (
    <>
      <div className='p-2 rounded-md offset-2 justify-end w-max float-right fade-in-left my-2 mx-2'>
        <div className="a flex flex-row-reverse gap-4 items-center">
          <BsPencil onClick={handleEditClick} className="cursor-pointer text-[20px]" />
          <MdDeleteOutline onClick={remove} className="cursor-pointer text-red-500 text-[25px]" />
        </div>
      </div>
    </>
  );
}
