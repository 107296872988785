import React from "react";
import Walletbalance from "./Walletbalance";
import Walletfrom from "./Walletfrom";
import { NavLink } from "react-router-dom";
import { HiArrowLongLeft } from "react-icons/hi2";
function Walletstock() {
  return (
    <>
      <NavLink to="/">
        <div
          className=" lg:relative lg:top-[-12px]  lg:w-[18%]  lg:left-[17rem]    flex flex-row item-center justify-start gap-3 z-10"
          style={{ alignItems: "center" }}
        >
          <span className="text-[32px] text-[#4b5563]">
            <HiArrowLongLeft />
          </span>
          <h1 className="font-semibold  text-[#4b5563] text-[20px]">Go Back</h1>
        </div>
      </NavLink>

      <div className="flex flex-col justify-center  bg-layout shadow-md drop-shadow-md lg:w-[152vh] lg:ml-[17rem] relative top-[1rem] swing-in-top-fwd items-center lg:mb-[8rem]">
        <div className="flex flex-row  mt-[4rem]">
          <Walletbalance />
          <Walletfrom />
        </div>
      </div>
    </>
  );
}

export default Walletstock;
