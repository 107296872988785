// WalletContext.js

import React, { createContext, useContext, useState, useEffect } from "react";
import {
  fetch_wallet_info,
  fetch_posts,
  fetch_Profile_Status,
} from "../../../api/userRegestration/CollectionForm";

const WalletContext = createContext();

export const WalletProvider = ({ children }) => {
  const [walletData, setWalletData] = useState();
  const [postlist, setPostlist] = useState();
  const [profiledata, setProfiledata] = useState({});
  33;
  //
  useEffect(() => {
    const currentDate = new Date();
    console.log(`Wallet - context created/updated at: ${currentDate}`);
  }, [walletData, profiledata]);

  // helper function for the fetching user wallet balance
  const fetch_wallet_balance = () => {
    fetch_wallet_info()
      .then((response) => {
        console.log("API Response:", response);
        setWalletData(response.result);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  // function for fetching post list
  const fetch_post_list = () => {
    fetch_posts()
      .then((respone) => {
        console.log("from post response", respone);
        setPostlist(respone.result);
      })
      .catch((error) => {
        console.error("Error post:", error);
      });
  };

  const fetch_profile_status = () => {
    fetch_Profile_Status()
      .then((response) => {
        console.log("API Response from contet:", response);
        setProfiledata(response.result);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  // executing functions
  
  useEffect(() => {
    fetch_wallet_balance();
    fetch_post_list();
    fetch_profile_status();
  }, []);

  return (
    <WalletContext.Provider
      value={{
        walletData,
        setWalletData,
        postlist,
        profiledata,
        setProfiledata,
        fetch_Profile_Status,
      }}
    >
      {children}
    </WalletContext.Provider>
  );
};

export const useWallet = () => {
  const context = useContext(WalletContext);
  if (!context) {
    throw new Error("useWallet must be used within a WalletProvider");
  }
  return context;
};
