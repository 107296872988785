import React from 'react'
import Map from './Map'
import Markettrend from './Markettrend'

function MapStock() {
  return (
    <>
    <div className="item-web xs:hidden">
    <div className="flex flex-col gap-3">
      <Map/>
      <Markettrend/>
      </div>
      </div>
      <div className="item-mobile hidden xs:block">
<div className="item-context"></div>
      <div className="border-xy bg-yellow-400 relative fixed  top-[28.3rem] right-[136px] shadow-md">
    <span className="block  group-hover:text-slate-800 transition duration-200 text-black font-all  text-[13px] p-[6px] text-center">
      Discove nearby vendors,trends and more....
    </span>
     </div>


 
      </div>
    </>
  )
}

export default MapStock
