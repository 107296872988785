import React, { useState, useEffect } from "react";
import { BsChevronCompactLeft, BsChevronCompactRight } from "react-icons/bs";
import { RxDotFilled } from "react-icons/rx";
export default function ListAllCarousel() {
  const slides = [
    {
      url: "https://images.unsplash.com/photo-1524678606370-a47ad25cb82a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1169&q=80",
    },
    {
      url: "https://images.unsplash.com/photo-1524678606370-a47ad25cb82a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1169&q=80",
    },
    {
      url: "https://images.unsplash.com/photo-1524678606370-a47ad25cb82a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1169&q=80",
    },
    {
      url: "https://images.unsplash.com/photo-1524678606370-a47ad25cb82a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1169&q=80",
    },
  ];
  const [currentIndex, setCurrentIndex] = useState(0);
  //
  const prevSlide = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const nextSlide = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex);
  };

  useEffect(() => {
    const timeout = setTimeout(nextSlide, 3000);
    return () => clearTimeout(timeout);
  }, [currentIndex]);
  return (
    <>
    <div className='max-w-[1520px] h-[362px] w-full m-auto  relative group'>
<div
style={{ backgroundImage: `url(${slides[currentIndex].url})` }}
className='w-full h-[362px] rounded-md bg-center bg-cover duration-500'
></div>

<div className=' absolute top-[48%] -translate-x-0 translate-y-[-50%] left-[22.75px] text-2xl rounded-md p-2 bg-black/20 text-white cursor-pointer'>
<BsChevronCompactLeft onClick={prevSlide} size={20} />
</div>
{/* Right Arrow */}
<div className=' absolute top-[48%] -translate-x-0 translate-y-[-50%] right-[22.75px] text-2xl rounded-md p-2 bg-black/20 text-white cursor-pointer'>
<BsChevronCompactRight onClick={nextSlide} size={20} />
</div>
<div className='flex top-4 justify-center py-2'>
{slides.map((slide, slideIndex) => (
<div
  key={slideIndex}
  onClick={() => goToSlide(slideIndex)}
  className='text-2xl cursor-pointer'
>
  <RxDotFilled className='text-[#CB2229]'/>
</div>
))}
</div>
</div> 
</>
  );
}
