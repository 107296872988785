import React from 'react'
const carouselData = [

 
  {
    name: "product name",
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTko7wO6ZZQX-qxqf4xATkFaRkF5sVwZZC-K4Zn_f4NhrLPJr2Z1ToLsmwntHW2c3mF-UI&usqp=CAU",
  },
  {
    name: "product name",
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTko7wO6ZZQX-qxqf4xATkFaRkF5sVwZZC-K4Zn_f4NhrLPJr2Z1ToLsmwntHW2c3mF-UI&usqp=CAU",
  },
  {
    name: "product name",
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTko7wO6ZZQX-qxqf4xATkFaRkF5sVwZZC-K4Zn_f4NhrLPJr2Z1ToLsmwntHW2c3mF-UI&usqp=CAU",
  },

];
export default function PendingPost() {
  return (
    <>

<hr className="w-full border-gray-500 mt-4 mb-3" />
<h1 className="text-center font-all font-semibold text-[23px]">
  Pending Post
</h1>
<hr className="w-full border-gray-500 mt-4 mb-3" />

<div className=" flex flex-wrap justify-center gap-4 mt-4">
{carouselData.map((item) => (
            <div className="carousel-card  " key={item.name}>
         
                <div className="lg:w-[246px] xs:w-[14rem] drop-shadow-xl">
                  <div className="lg:h-[242px] lg:w-[214px]   shadow-inner pb-24 rounded-lg overflow-hidden text-center relative intrest-card bg-[#ffff] h-[137px]  ">
                    <img
                      src={item.image}
                      className="lg:h-[142px] l:w-[242px]"
                      alt=""
                    />
                    <h5 className="text-black font-all font-bold lg:mt-[9px]">
                      {item.name}
                    </h5>
                    <p className="font-all text-[#263238] lg:w-[190px] inline text-[13px] font-semibold">
                 text for the dsierd product 
                    </p>
                  </div>
                </div>
           
            </div>
          ))}
</div>
    </>
  )
}
