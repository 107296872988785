import React, { useEffect } from 'react';

import avatar from "../images/avatar.png";
import chart from "../images/chart.png";
import { useNavigate } from "react-router-dom";
import { useWallet } from '../../../Wallet/Context/WalletContext';

export default function ResellerDashboardMain() {
  const { profiledata } = useWallet();
  const navigate = useNavigate();
  const routetable = () => {
    navigate(`/Resellerdashboard-userdetail`);
  };
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <>

      <div className="  bg-layout shadow-md drop-shadow-md lg:w-[155vh]  mb-[7rem]  lg:ml-[268px] relative top-[1rem] fade-in-left p-[9px]">
        <h1 className="font-all  font-extrabold text-start text-[25px] ml-8 mt-8 mb-7 ">
          Your Dashboard
        </h1>
        {profiledata && <>
          <div className="flex flex-col gap-7 mb-[3rem]">
          <div className="flex flex-row items-center justify-center gap-4">
            <div className="-item-a">
              <div className="border rounded-md border-[#D2D2D2] p-[15px]">
                <div className="flex flex-row">
                  <div className="flex flex-col ">
                    <h1 className="font-all font-semibold text-[19px] m">
                      Applicants
                    </h1>
                    <div className="--img mt-[3rem] relative left-[2rem]">
                      <img
                        src={avatar}
                        className="h-[9rem] img-coin image-coin"
                        alt=""
                      />
                    </div>
                    <div className="flex flex-col gap-2 mt-[12px]">
                      <div className="text-start font-all font-semibold text-[23px]">
                      {profiledata.referredUserCount } user
                      </div>
                      <div className="text-start font-all font-semibold">
                        Registerd
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col gap-3 ml-[6rem]">
                    <div
                      className="h-[9rem] w-[20rem] bg-[#e98f9275] cursor-pointer rounded-md"
                      onClick={routetable}
                    >
                      <div
                        className="flex flex-col justify-center items-center mt-8
"
                      >
                        <span className="font-all font-semibold lg:text-[30px] ">
                          100
                        </span>
                        <span className="font-all ">individual</span>
                      </div>
                    </div>

                    <div
                      className="h-[9rem] w-[20rem] bg-[#E98F92] cursor-pointer rounded-md"
                      onClick={routetable}
                    >
                      <div className="flex flex-col justify-center items-center mt-8 ">
                        <span className="font-all font-semibold lg:text-[30px] ">
                          900
                        </span>
                        <span className="font-all ">Corporate user</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="-item-b">
              <div className="-item-b">
                <div className="border rounded-md border-[#D2D2D2] p-[15px]">
                  <h1 className="font-all font-semibold text-[19px] m">
                    Active Funds
                  </h1>
                  <div className="--img cursor-pointer" onClick={routetable}>
                    <img src={chart} className="h-[14rem]" alt="" />
                  </div>

                  <div className="flex flex-row justify-center items-center gap-[9rem]">
                    <h1 className="flex flex-col">
                      {" "}
                      <span className="font-all font-semibold">
                        {" "}
                        100 <br /> <span>earning</span>
                      </span>{" "}
                    </h1>

                    <h1 className="flex flex-col">
                      {" "}
                      <span className="font-all font-semibold">
                        {" "}
                        100 <br /> <span>earning</span>
                      </span>{" "}
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="border border-[#D2D2D2] rounded-md w-[93%] mx-auto">
            <div className="flex flex-row p-[15px] ">
              <div className="a flex flex-col gap-[6rem]">
                <p className="font-all font-semibold text-[21px]">
                  Your Earning
                </p>
                <p className="flex flex-col">
                  <span className="font-all font-semibold text-[19px]">
                    200000
                  </span>
                  <span className="font-all">Total Earning</span>
                </p>
              </div>

              <div className="b">
                <div className="flex flex-row gap-3 ml-[6rem]">
                  <div
                    className="h-[11rem] w-[20rem] bg-[#e98f9275] rounded-md cursor-pointer"
                    onClick={routetable}
                  >
                    <div
                      className="flex flex-col justify-center items-center mt-12
"
                    >
                      <span className="font-all font-semibold lg:text-[30px] ">
                        100
                      </span>
                      <span className="font-all ">individual</span>
                    </div>
                  </div>
                  <div
                    className="h-[11rem] w-[20rem] bg-[#E98F92] rounded-md cursor-pointer"
                    onClick={routetable}
                  >
                    <div className="flex flex-col justify-center items-center mt-12 ">
                      <span className="font-all font-semibold lg:text-[30px] ">
                        900
                      </span>
                      <span className="font-all ">Corporate user</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        </>  }
<>
     

        </>

      </div>
    </>
  );
}
