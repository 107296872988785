import React from 'react'

export default function GovtOtherProjects() {
  return (
   <>
   
   
   <div className="border border-gray-400 rounded-md w-[99%] mx-auto mt-9 ">

<div className="flex flex-row justify-start  items-center ml-8 gap-[50rem] p-[11px]">
<h1 className="font-all font-semibold text-[22px]">
  Gonvernment Other  Projects
</h1>
{/*  */}
{/*  */}



  </div>
  {/*  */}

<div className="flex flex-wrap  gap-4 mt-4 justify-center mb-6">

<img src='https://img.freepik.com/free-photo/tall-steel-skyscraper-reflects-blue-twilight-sunlight-glow-generated-by-ai_188544-41811.jpg?t=st=1688853783~exp=1688857383~hmac=c352cb44151a614899bcccb3a7a765affb137db8624ed8c7c2d3f1462d6f9ec0&w=1380' className='h-[224px] rounded-md' alt="" />
<img src="https://img.freepik.com/free-photo/smoke-rises-from-factory-chimney-polluting-nature-generated-by-ai_188544-20356.jpg?size=626&ext=jpg&ga=GA1.1.2054915257.1675252966&semt=ais_ai_generated" className='h-[224px] rounded-md' alt="" />
<img src="https://img.freepik.com/free-photo/metal-worker-using-machinery-industrial-workshop-generated-by-ai_188544-19969.jpg?size=626&ext=jpg&ga=GA1.1.2054915257.1675252966&semt=ais_ai_generated" className='h-[224px] rounded-md' alt="" />

</div>

    </div>
   
   </>
  )
}
