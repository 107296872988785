import React from 'react'
import { Outlet } from 'react-router-dom'

export default function ResellerLayout() {
  return (
    <div>
  <Outlet/>
      
    </div>
  )
}
